.pavesi {
  padding: 20px;

  .uoa-select {
    width: 200px;
    display: flex;
  }

  .charts-row {
    display: flex;
    gap: 24px;
    margin-top: 60px;
  }

  .charts-row > div {
    flex: 1;
  }

  .MuiCard-root {
    background-color: #fafafa;
  }

  .section-title {
    height: 55px;
    font-size: 18px;
    font-weight: 600;
    color: #699418;
  }

  .filter {
    display: flex;
    align-items: center;
  }

  .space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}