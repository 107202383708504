.uoa-select {
  width: 100%;
  display: flex;
}

.data-filter {
  display: grid;
  align-items: center;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  gap: 1rem;
}

@media (min-width: 768px) {
  .data-filter {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (min-width: 1024px) {
  .data-filter {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media (min-width: 1280px) {
  .data-filter {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
}