.container {
  padding: 20px;

  .loader-container {
    width: 100%;
  }

  .section-title {
    padding-top: 12px;
    padding-bottom: 12px;
    font-size: 18px;
    font-weight: 600;
    color: #002a4f;
  }

  .container-row {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    .col-span-2 {
      grid-column: span 2 / span 2;
    }

    .gap-4 {
      gap: 1rem;
    }

    .py-0 {
      padding-top: 0;
      padding-bottom: 0;
    }

    .MuiCard-root {
      background-color: #fafafa;
    }
  }
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}