.pavesi-homepage {
  padding: 20px;

  .beneficiary-charts {
    display: flex;
    gap: 24px;
    margin: 20px 0;

    &__item {
      display: flex;
      flex-direction: column;
      gap: 20px;
      flex: 1;
    }
  }

  .column-charts {
    &__row {
      display: flex;
      gap: 24px;
      margin-bottom: 20px;
    }

    &__row > div {
      flex: 1;
    }
  }

  .project-select {
    width: 200px;
    display: flex;
  }

  .MuiCard-root {
    background-color: #fafafa;
  }

  .section-title {
    height: 55px;
    font-size: 18px;
    font-weight: 600;
  }

  .filter {
    display: flex;
    align-items: center;
  }

  .space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
  }
}
