.charts {
  display: flex;
  gap: 24px;

  &_average {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    &_value {
      font-size: 30px;
    }
  }
}

.charts > div {
  flex: 1;
}
