.competenze-educative {
  margin: 20px 0;

  &__charts {
    display: flex;
    gap: 24px;

    &_average {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;

      &_title {
        color: #699418;
        font-size: 18px;
        font-weight: 600;
      }

      &_value {
        font-size: 30px;
      }
    }
  }

  &__charts > div {
    flex: 1;
  }
}
