.container {
  padding: 20px;
  font-size: 1.5rem;
  line-height: 1.5rem;

  .loader-container {
    width: 100%;
  }

  .filter-select {
    width: 25%;
    display: flex;
    min-width: 400px;

    @media (max-width: 768px) {
      width: 50%;
      min-width: unset;
    }

    @media (max-width: 425px) {
      width: 100%;
      min-width: unset;
    }
  }

  .row {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.filter {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 1rem;

  @media (max-width: 425px) {
    flex-direction: column;
  }
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.content-title {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.card_value {
  font-weight: 900;
  font-size: 3.25rem;
  color: #4c5773;
  margin-block-end: 0.67em;
}

.card_value:hover,
.card_text:hover {
  color: rgb(80, 158, 227);
}

.card_text {
  font-size: 1.15rem;
  color: #4c5773;
  font-weight: 700;
}

.card-content {
  line-height: 1.4rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.h-full {
  height: 100%;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.col {
  flex: 1;
}

.pa-2 {
  padding: 2px;
}
