.container-parent {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 24px;

  .section-title {
    display: flex;
    align-items: center;
    gap: 6px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
  }

  .MuiCard-root {
    background-color: #fafafa;
  }
}

.container-child {
  display: grid;
  gap: 24px;
}

.container-parent > div {
  flex: 1;
}

.chart-container {
  display: flex;
  gap: 12px;
}

.chart-container > div {
  flex: 1;
}

.grid-cols-2 {
  grid-template-columns:repeat(2, minmax(0, 1fr))
}

.grid-cols-3 {
  grid-template-columns:repeat(3, minmax(0, 1fr))
}

.text-center {
  text-align: center;
}

.text-primary {
  color: #002f87;
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2px;
}

.mt-12 {
  margin-top: 12px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}