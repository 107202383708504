.attentamente-parent {
  padding: 20px;
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(2, minmax(0, 1fr));

  .section-title {
    display: flex;
    align-items: center;
    gap: 6px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
  }

  .MuiCard-root {
    background-color: #fafafa;
  }
}

.loader-container {
  grid-column: span 2 / span 2;
}

.attentamente-parent > div {
  flex: 1;
}
