.container {
    padding: 20px;
    font-size: 1.5rem;
    line-height: 1.5rem;

    .loader-container {
        width: 100%;
    }

    .uoa-select {
        width: 100%;
        display: flex;
    }

    .row {
        display: grid;
        gap: 24px;
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.data-filter {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    gap: 1rem;
}

.space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(1rem * var(--tw-space-x-reverse));
    margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

.space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-x-reverse: 0;
    margin-right: calc(0.5rem * var(--tw-space-x-reverse));
    margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse)));
}

.content-title {
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.bold {
    font-weight: bold;
}

.col-span-2 {
    grid-column: span 2 / span 2;
}

.pa-4 {
    padding: 1rem;
}

.px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
}

.flex {
    display: flex;
}

.items-baseline {
    align-items: baseline;
}

.text-left {
    text-align: left;
}

.mx-auto {
    margin-left: auto;
    margin-right: auto;
}

.text-sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.my-0 {
    margin-top: 0;
    margin-bottom: 0;
}

.mt-1 {
    margin-top: 0.25rem;
}

.row-3 {
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(3, minmax(0, 1fr));
}

.justify-between {
    justify-content: space-between;
}

.justify-end {
    justify-content: end;
}

.basis-full {
    flex-basis: 100%;
}

.lg\:basis-1\/2 {
    @media (min-width: 1024px) {
        flex-basis: 50%;
    }
}

.flex-wrap {
    flex-wrap: wrap;
}

.max-w-full {
    max-width: 100%;
}


@media (min-width: 768px) {
    .data-filter {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 1024px) {
    .data-filter {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

@media (min-width: 1280px) {
    .data-filter {
        grid-template-columns: repeat(8, minmax(0, 1fr));
    }
}