.api-table-container {
  padding: 20px;
  margin-top: 10px;
  background: rgb(249, 250, 251) !important;
  width: 100%;
  font-size: 16px;
}

.text-container {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis
}

.text-right {
  text-align: right;
}

.w-200 {
  width: 200px;
}