.attentamente-child {
  &__title {
    font-size: 20px;
    font-weight: 600;
    padding: 20px 0 10px 20px;
  }

  &__row {
    padding: 20px;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  &__row > div {
    flex: 1;
  }

  .section-title {
    display: flex;
    align-items: center;
    gap: 6px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
  }

  .MuiCard-root {
    background-color: #fafafa;
  }
}

.attentamente-child > div {
  flex: 1;
}
