.attentamente-parent {
  padding: 20px;
  display: flex;
  gap: 24px;
  flex-wrap: wrap;

  .section-title {
    display: flex;
    align-items: center;
    gap: 6px;
    height: 55px;
    font-size: 18px;
    font-weight: 600;
  }

  .MuiCard-root {
    background-color: #fafafa;
  }
}

.attentamente-parent > div {
  flex: 1;
}

div .w-full {
  flex: 0 0 100%;
}